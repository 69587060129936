<template>
  <div class="updatePassword" :style="'height: ' + windowHeight + 'px;'">

    <!-- <rxNavBar v-if="messageTo" title="付款审批"></rxNavBar> -->
    <van-nav-bar v-if="messageTo" title="付款审批" left-arrow @click-left="_onBackTapInfo" fixed z-index="99" placeholder />
    <rxNavBar v-else title="付款审批" androidOrIOSFlag="true"></rxNavBar>

    <div class="img_group">
     <img :src="headImg" class="oneImg">
     <div class="setPsd">付款审批</div>
    </div>

    <div class="appointmentDetail">
      <div class="relevvant-detail relevvant-detail-border" @click="paymentApproval(1)">
        <div style="height:1.2rem;float: left"><img :src="houseRelevantRenterImg" class="detail-img"></div>
       
        <span class="houseRelevantText">业主类</span>
         <van-tag round type="danger" style="margin-left: 10px;" v-if="paramData.ownerKind && paramData.ownerKind!=0">{{paramData.ownerKind > 99 ? '99+' : paramData.ownerKind}}</van-tag>
      </div>
      <div class="relevvant-detail relevvant-detail-border" @click="paymentApproval(2)">
        <div style="height:1.2rem;float: left"><img :src="houseRelevantRenterImg1" class="detail-img"></div>
       
        <span class="houseRelevantText">渠道费</span>
         <van-tag round type="danger" style="margin-left: 10px;" v-if="paramData.channelFee && paramData.channelFee!=0">{{paramData.channelFee > 99 ? '99+' : paramData.channelFee}}</van-tag>
      </div>
      <div class="relevvant-detail relevvant-detail-border" @click="paymentApproval(3)">
        <div style="height:1.2rem;float: left"><img :src="houseRelevantRenterImg3" class="detail-img"></div>
       
        <span class="houseRelevantText">解除类</span>
         <van-tag round type="danger" style="margin-left: 10px;" v-if="paramData.relieveKind && paramData.relieveKind!=0">{{paramData.relieveKind > 99 ? '99+' : paramData.relieveKind}}</van-tag>
      </div>
      <div class="relevvant-detail relevvant-detail-border" @click="paymentApproval(4)">
        <div style="height:1.2rem;float: left"><img :src="houseRelevantRenterImg2" class="detail-img"></div>
       
        <span class="houseRelevantText">装修款</span>
         <van-tag round type="danger" style="margin-left: 10px;" v-if="paramData.decorationFee && paramData.decorationFee!=0">{{ paramData.decorationFee > 99 ? '99+' : paramData.decorationFee}}</van-tag>
      </div>
      <div class="relevvant-detail relevvant-detail-border" @click="paymentApproval(5)">
        <div style="height:1.2rem;float: left"><img :src="houseRelevantRenterImg4" class="detail-img"></div>
       
        <span class="houseRelevantText">租后类</span>
         <van-tag round type="danger" style="margin-left: 10px;" v-if="paramData.afterRentKind && paramData.afterRentKind!=0">{{paramData.afterRentKind > 99 ? '99+' : paramData.afterRentKind}}</van-tag>
      </div>
      <div class="relevvant-detail relevvant-detail-border" @click="paymentApproval(15)">
        <div style="height:1.2rem;float: left"><img :src="houseRelevantRenterImg5" class="detail-img"></div>
       
        <span class="houseRelevantText">中后台</span>
         <van-tag round type="danger" style="margin-left: 10px;" v-if="paramData.middleGround && paramData.middleGround!=0">{{paramData.middleGround > 99 ? '99+' : paramData.middleGround}}</van-tag>
      </div>
    </div>
  </div>
</template>

<script>
import {
  NavBar,
  NumberKeyboard,
  Toast,
  Calendar,
  DatetimePicker,
  Button,
  Popup,
  Tag,
  Badge 
} from 'vant'

import {
  checkAndroid, checkIOS, getStaffId,
  globaluserId,
  money, openInWebview,
  responseUtil
} from "../../../libs/rongxunUtil";
import {
  getStaffFuntionModelList,queryMyPageInit,payApprovalCount
} from "../../../getData/getData";
import { checkAuthList } from "../../../libs/rongxunUtil";

//调用android关闭页面方法*******begin*********
function backToAndroid(){
  window.himi.closeWindow();
}
import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
export default {
  name: 'houseRelevant',
  components: {
    [NavBar.name]: NavBar,
    [NumberKeyboard.name]: NumberKeyboard,
    [Toast.name]: Toast,
    [Button.name]: Button,
    [Popup.name]: Popup,
    [Calendar.name]: Calendar,
    [DatetimePicker.name]: DatetimePicker,
    rxNavBar,
    [Tag.name]: Tag,
    [Badge.name]: Badge,
    
  },
  data() {
    return {
      allCount: 100,
      userName:'',
      number:'617828',
      windowHeight: document.documentElement.clientHeight,   //实时屏幕高度
      errorImg: 'https://himihome.oss-cn-qingdao.aliyuncs.com/images/platform/templatetmp/2589de77-4277-4be7-add9-f09f2b176e57.png',
      headImg: require("../../../assets/images/head-quotation-white.png"),
      // houseRelevantRenterImg: require("../../../assets/images/house-relevant-renter.png"),  // 租客
      // houseRelevantRenterImg1: require("../../../assets/images/wamp.png"),  // 渠道
      // houseRelevantRenterImg2: require("../../../assets/images/statistics-maintain.png"),  // 装修
      // houseRelevantRenterImg3: require("../../../assets/images/abandan.png"),  // 解除
      // houseRelevantRenterImg4: require("../../../assets/images/cleanPeople2x.png"),  // 租客
      // houseRelevantRenterImg5: require("../../../assets/images/house-relevant-cut.png"),  // 租客
      houseRelevantRenterImg: require("../../../assets/images/house-relevant-renter.png"),  // 租客
      houseRelevantRenterImg1: require("../../../assets/images/qudao_8.png"),  // 租客
      houseRelevantRenterImg2: require("../../../assets/images/house-relevant-offer.png"),  // 租客
      houseRelevantRenterImg3: require("../../../assets/images/jiechu_7.png"),  // 租客
      houseRelevantRenterImg4: require("../../../assets/images/zuhou_8.png"),  // 租客
      houseRelevantRenterImg5: require("../../../assets/images/house-relevant-upload.png"),  // 租客
      houseRelevantRenterImg6: require("../../../assets/images/mapt.png"),  // 租客
      houseRelevantRenterImg7: require("../../../assets/images/img_jichurenshi1.png"),  // 租客
      houseRelevantRenterImg8: require("../../../assets/images/img_caiwu1.png"),  // 租客
      houseRelevantRenterImg9: require("../../../assets/images/img_kehufuwu1.png"),  // 租客
      authButtonsList:[],
      paramData: {},
      messageTo: false,
      routerBack: '',
      routerParams: {},
    }
  },
  mounted() {
    // this.getStaffFuntionModelList()
    // console.log("this.$router.history.current==",this.$router.history.current)
    this.queryMyPageInit()
    this.getpayApprovalCount()
    if(this.$route.query.messageFlag && this.$route.query.messageFlag == 1) {
				this.messageTo = true
		}
  },
  methods: {
    // 经过桥跳转至原生页面
    _onBackTapInfo() {
      window.webkit.messageHandlers.goToStaffHome.postMessage({});
    },
    paymentApproval(e) {
            this.$router.push({
              name: 'unPaid',
              query: {
                type:e
              }
            })
          },
    getpayApprovalCount() {
      var that = this
        let data = {}
        data.staff_id = getStaffId()
        payApprovalCount(data).then(function (response) {
            responseUtil.dealResponse(that, response, () => {
              that.paramData = response.data.data
              
            })
        })
    },
    //跳转租客合同
    skipRenterContract(){
      this.$router.push({
        name: 'portApproveList',
        query: {
          fromType:'0',
          type: 1,
          room_id:this.roomId,
          setHouse_id:this.setHouse_id,
          currentLabel: '0',  // 全部
        }
      })
    },
    //修改工资卡
    alertPayCard(){
      this.$router.push({
        name: 'staffPayCard',
        query: {

        }
      })
    },
    //房源列表
    houseList(){
      this.$router.push({
        name: 'houseList',
        query: {

        }
      })
    },
    //工资审批
    salaryApplication(){
      this.$router.push({
        name: 'salaryApproval',
        query: {

        }
      })
    },
    //通讯录
    addressBook(){
      this.$router.push({
        name: 'addressBook',
        query: {

        }
      })
    },
    //业务类手册
    businessManual(){
      this.$router.push({
        name: 'businessManual',
        query: {

        }
      })
    },
    leftReturn() {
      if(openInWebview()){
        //APP传值*******begin*********
        if(checkAndroid()){
          backToAndroid();
        }else if(checkIOS()){
          window.webkit.messageHandlers.closeWindow.postMessage({});
        }
        //APP传值*******end*********
      }else{
        this.$router.go(-1)
      }
    },
    //权限方法
    getStaffFuntionModelList () {
        var that = this
        let data = {}
        data.staff_id = getStaffId()
        data.menuName = 'staffMore_index'
        getStaffFuntionModelList(data).then(function (response) {
            responseUtil.dealResponse(that, response, () => {
                that.authButtonsList = response.data.data.data
            })
        })
    },
    queryMyPageInit () {
        var that = this
        let data = {}
        data.user_id = globaluserId()
      queryMyPageInit(data).then(function (response) {
            responseUtil.dealResponse(that, response, () => {
              console.log(response.data.data.userName)
              that.userName = response.data.data.userName
            })
        })
    },
    checkAuthList,
  }
}
</script>

<style scoped>
/*保存按钮不可点击样式*/
.saveButton_Disable{
  /*background: linear-gradient(to right, rgba(184, 184, 184, 0.2), #B8B8B8 20%) repeat scroll 0% 0%;*/
  background-color: rgba(184, 184, 184, 0.2);
  width: 345px;
  height: 45px;
  border-radius: 8px;
  margin:120px 15px 15px 15px;
  font-size: 18px;
  color: white;
  line-height: 1.22rem;
  text-align: center;
}
.saveButton_Enable{
  background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;
  color: white;
  width: 345px;
  height: 45px;
  border-radius: 8px;
  margin:120px 15px 15px 15px;
  font-size: 18px;
  line-height: 1.22rem;
  text-align: center;
}

  .changeStatusStyle {
    height: 2rem;
  }
  .confirmCancelOrdersText {
    margin-left: 35px;
    margin-bottom: 15px;
    font-size: 15px;
    font-weight: bold
  }

  .cancelOrdersImg {
    float: left;
    margin: 20px 5px 0 15px;
    width: 15px;
    height: 15px;
  }
  .cnacelOrdersText {
    margin-top: 18px;
    font-size: 15px;
    font-weight: bold
  }

.relevvant-detail {
    position: relative;
  width: 100%;
  height: 1.2rem;

  font-size: 15px;
  line-height: 1.2rem;
}
.relevvant-detail-border {
  border-bottom: #EFEFEF 1px solid;
  border-radius: 8px;
}


.detail-img {
  height:0.4rem;
  margin-left: 20px;
  padding-top: 0.42rem;
}

.houseRelevantText {
  margin-left: 10px;
  font-family: PingFangSC-Semibold;
  font-weight: bold;
}

.updatePassword {
  background: linear-gradient(to bottom, #FFC274 0px, #FF5D3B 100%) repeat scroll 0% 0%;
  height: 18rem;
}
.content-divide {
  font-size: 12px;
  color: #efefef;
  margin-left:10px;
  margin-right:5px;
}
.content-none {
  padding: 3px;
  margin: 0 15px;
  border-radius: 50%;
  background-color: #c7c7c7;
}
.content-have {
  padding: 3px;
  margin: 0 15px;
  border-radius: 50%;
  background-color: #ff5d3b;
}
.roomMesage{
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding: 5px;
}
.areaDiv{
  display: flex;
  align-items: center;
  margin-top: 7px;
  font-size: 12px;

}
.area {
  color: rgba(102, 102, 102, 1);
  font-size: 12px;
  text-align: left;
  font-family: PingFangSC-Regular;
}

.devide {
  color: rgba(102, 102, 102, 1);
  font-size: 12px;
  margin: 0px 7px;
  font-family: PingFangSC-Regular;

}

.roomPhoto {
  width: 151.2px;
  height: 100.08px;
  /*padding: 5px;*/
  margin: 5px;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-size: cover;
}
.roomPhotoImage{
  border-radius: 8px;
  width: 100%;
  height: 100%;
}
.submitAppointment {
  width: 345px;
  height: 45px;
  border-radius: 8px;
  margin: 210px 15px 20px 15px;
  background: linear-gradient(to right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
  font-size: 16px;
  text-align: center;
  font-family: PingFangSC-Semibold;
  color: white;
  line-height: 45px;
}

.inputDate {
  width: 190px;
  height: 20px;
  /*margin-left: 110px;*/
  outline-style: none;
  border: 0px;
  font-size: 15px;
  text-align: left;
  font-family: PingFangSC-Regular;
  color: rgba(255, 93, 59, 1);
}

.devide2 {
  width: 1px;
  height: 14px;
  color: rgba(239, 239, 239, 1);
}

.timeText {
  height: 21px;
  color: rgba(34, 34, 34, 1);
  font-size: 15px;
  text-align: left;
  font-family: PingFangSC-Semibold;
}




.timePick {
  width: 18.56px;
  height: 19.24px;
  /*margin-left: 15px;*/
}

.timeDiv {
  width: 92%;
  height: 50px;
  margin: 0 auto;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
  margin-top: 15px;
  display: flex;
  align-items: center;
}

.spanMonth {
  color: rgba(255, 93, 59, 1);
  font-size: 10px;
  text-align: left;
  font-family: PingFangSC-Regular;
}

.money {
  width: 70px;
  height: 24px;
  margin-top: 7px;
  color: rgba(255, 93, 59, 1);
  font-size: 16px;
  font-family: Oswald-Regular;

}

.address {
  color: rgba(34, 34, 34, 1);
  font-size: 15px;
  font-family: PingFangSC-Regular;

}
.img_group {
  height: 75px;
  margin: 29px 0px;
}

.setPsd {
  width: 112px;
  height: 40px;
  color: white;
  font-size: 28px;
  text-align: left;
  font-family: PingFangSC-Semibold;
  position: relative;
  font-weight: bold;
  left: 24px;
  bottom: 40px;
  white-space: nowrap;
}

.oneImg {
  width: 58px;
  height: 66px;
}

.appointmentDetail {
  width: 92%;
  margin: 0 auto;
  height: auto;
  /*display: flex;*/
  /*align-items: flex-start;*/
  /*justify-content: space-between;*/
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);

}
.selectTime{
  width: 10rem;
  height: 3rem;
}
.cicleWarn {
    position: absolute;
    top: -5px;
    right: -5px;
}


</style>
